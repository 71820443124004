import React, { cloneElement } from 'react';
import { isAdmin } from '../utils';
import ExportButton from '../ExportButton';
import PropTypes from 'prop-types';
import {
  useListContext,
  usePermissions,
  sanitizeListRestProps,
  TopToolbar,
  CreateButton,
} from 'react-admin';

const UserBenefitListActions = ({ className, filters, ...props }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(props)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {isAdmin(permissions) && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        targetClass={'App\\Entity\\UserBenefit'}
        filters={filterValues}
      />
    </TopToolbar>
  );
};

UserBenefitListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
  permissions: PropTypes.array,
};

export default UserBenefitListActions;
