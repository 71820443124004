import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import {
  TextField,
  NumberField,
  BooleanField,
  ChipField,
  FunctionField,
  DateField,
  ReferenceField,
  TextInput,
  NumberInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  ArrayField,
  SelectArrayInput,
  required as assertRequired,
  RichTextField,
  SingleFieldList,
} from 'react-admin';
import { useForm } from 'react-final-form';
import RichTextInput from "ra-input-rich-text";

export const JsonFormFieldTypes = {
  array: 'ARRAY',
  bool: 'BOOL',
  chip: 'CHIP',
  date: 'DATE',
  html: 'HTML',
  func: 'FUNC',
  number: 'NUMBER',
  reference: 'REFERENCE',
  string: 'STRING',
};

export const JsonFormInputTypes = {
  array: 'ARRAY',
  bool: 'BOOL',
  date: 'DATE',
  htmlEditor: 'HTML-EDITOR',
  number: 'NUMBER',
  reference: 'REFERENCE',
  select: 'SELECT',
  string: 'STRING',
};

export const JsonFormField = ({ formFieldType, source, record, label, render, ...props }) => {
  const _renderFunc =
    render ??
    (() => {
      console.error(`Json form field: ${source} of type ${formFieldType} requires a render function`);
      return null;
    });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      style={{ margin: '5px 0' }}
    >
      <Typography
        style={{ marginBottom: '5px' }}
        className={['MuiFormLabel-root', 'MuiInputLabel-shrink']}
      >
        {label}
      </Typography>
      {formFieldType === JsonFormFieldTypes.string && (
        <TextField label={label} source={source} record={record} {...props} />
      )}
      {formFieldType === JsonFormFieldTypes.number && (
        <NumberField label={label} source={source} record={record} {...props} />
      )}
      {formFieldType === JsonFormFieldTypes.bool && (
        <BooleanField label={label} source={source} record={record} {...props} />
      )}
      {formFieldType === JsonFormFieldTypes.chip && (
        <ChipField label={label} source={source} record={record} {...props} />
      )}
      {formFieldType === JsonFormFieldTypes.func && (
        <FunctionField
          label={label}
          record={record}
          render={(record) => _renderFunc(record)}
          {...props}
        />
      )}
      {formFieldType === JsonFormFieldTypes.date && <DateField source={source} record={record} />}
      {formFieldType === JsonFormFieldTypes.reference && (
        <ReferenceField label={label} source={source} record={record} {...props}>
          <FunctionField render={(record) => _renderFunc(record)} />
        </ReferenceField>
      )}
      {formFieldType === JsonFormFieldTypes.array && (
        <ArrayField label={label} source={source} record={record} {...props}>
          <SingleFieldList>
            <FunctionField render={(record) => _renderFunc(record)} />
          </SingleFieldList>
        </ArrayField>
      )}
      {formFieldType === JsonFormFieldTypes.html && (
          <RichTextField label={label} source={source} record={record} {...props} />
      )}
    </Box>
  );
};
JsonFormField.propTypes = {
  formFieldType: PropTypes.any,
  source: PropTypes.string,
  record: PropTypes.object,
  label: PropTypes.string,
  render: PropTypes.func,
  props: PropTypes.object,
};

export const JsonFormInput = ({
  onChange,
  formFieldType,
  source,
  record,
  label,
  render,
  required,
  ...props
}) => {
  const form = useForm();
  const _renderFunc =
    render ??
    (() => {
      console.log(`Json form input: ${formFieldType} requires a render function`);
      return null;
    });
  const validators = required ? [assertRequired(), ...(props?.['validate'] ?? [])] : props?.['validate'];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      style={{ margin: '5px 0' }}
    >
      {formFieldType === JsonFormInputTypes.string && (
        <TextInput
          {...props}
          onChange={(event) => onChange(form, source, event?.target?.value, event)}
          label={label}
          initialValue={record?.[source]}
          source={source}
          record={record}
          validate={validators}
        />
      )}
      {formFieldType === JsonFormInputTypes.htmlEditor && (
        <RichTextInput
          {...props}
          // the rich-text input returns directly the input value
          onChange={(event) => onChange(form, source, event, undefined)}
          label={label}
          initialValue={record?.[source]}
          source={source}
          record={record}
          validate={validators}
        />
      )}
      {formFieldType === JsonFormInputTypes.number && (
        <NumberInput
          {...props}
          onChange={(event) =>
            onChange(
              form,
              source,
              !isNaN(event?.target?.value) ? parseFloat(event.target.value) : undefined,
              event
            )
          }
          label={label}
          initialValue={record?.[source]}
          source={source}
          record={record}
          validate={validators}
        />
      )}
      {formFieldType === JsonFormInputTypes.bool && (
        <BooleanInput
          {...props}
          // the boolean input returns directly the input value
          // todo: change when updating the library
          onChange={(value) => onChange(form, source, value, undefined)}
          label={label}
          initialValue={record?.[source]}
          source={source}
          record={record}
          validate={validators}
        />
      )}
      {formFieldType === JsonFormInputTypes.date && (
        <DateInput
          {...props}
          onChange={(event) => onChange(form, source, event?.target?.value, event)}
          source={source}
          initialValue={record?.[source]}
          record={record}
          validate={validators}
        />
      )}
      {formFieldType === JsonFormInputTypes.reference && (
        <ReferenceInput
          {...props}
          onChange={(event) => onChange(form, source, event?.target?.value, event)}
          initialValue={record?.[source]}
          source={source}
          record={record}
          validate={validators}
        >
          <SelectInput optionText={(record) => _renderFunc(record)} />
        </ReferenceInput>
      )}
      {formFieldType === JsonFormInputTypes.select && (
        <SelectInput
          {...props}
          onChange={(event) => onChange(form, source, event?.target?.value, event)}
          source={source}
          initialValue={record?.[source]}
          record={record}
          validate={validators}
        ></SelectInput>
      )}
      {formFieldType === JsonFormInputTypes.array && (
        <SelectArrayInput
          {...props}
          onChange={(event) => onChange(form, source, event?.target?.value, event)}
          source={source}
          initialValue={record?.[source]}
          record={record}
          optionText={(record) => _renderFunc(record)}
          translateChoice={false}
          validate={validators}
        ></SelectArrayInput>
      )}
    </Box>
  );
};
JsonFormInput.propTypes = {
  onChange: PropTypes.func,
  formFieldType: PropTypes.any,
  source: PropTypes.string,
  record: PropTypes.object,
  label: PropTypes.string,
  render: PropTypes.func,
  required: PropTypes.bool,
  props: PropTypes.object,
};

/**
 * @name FormFieldMap
 * @type {{
 *     "id": number|string,
 *     "type": string,
 *     "source": string,
 *     "label": string,
 *     "render": (render:any) => string|JSX.Element,
 *     "required": boolean,
 *     "props": {}|undefined,
 * }}
 */

/**
 * Render fields or input components for json objects in your record
 *
 * @param {"field"|"input"|undefined} mode
 * @param {string|undefined} source
 * @param {string|undefined} label
 * @param {object|{}} record
 * @param {Array<FormFieldMap>} formFieldMap
 * @param {any} props
 * @param {CSSStyleDeclaration|undefined} style
 * @return {JSX.Element|null}
 * @constructor
 */
export const JsonForm = ({ mode, source, label, record, formFieldMap, style, ...props }) => {
  const [obj, setObj] = useState(record?.[source] ?? {});

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ gap: '20px', ...style }}
    >
      {label != null && (
        <Typography
          style={{ padding: '1.5rem 0 1rem', width: '100%', textAlign: 'center' }}
          variant={'h6'}
        >
          {label}
        </Typography>
      )}
      {mode === 'input' &&
        formFieldMap.map((field, index) => (
          <JsonFormInput
            {...props}
            onChange={(form, key, value) => {
              const newObj = { ...obj };
              newObj[key] = value;
              form.change(source, newObj);
              setObj(newObj);
            }}
            formFieldType={field?.type}
            source={field?.source}
            record={obj}
            label={field?.label}
            render={field?.render}
            required={field?.required}
            {...(field?.props ?? {})}
            key={index}
          />
        ))}
      {(!mode || mode === 'field') &&
        formFieldMap.map((field, index) => (
          <JsonFormField
            {...props}
            formFieldType={field?.type}
            source={field?.source}
            record={obj}
            label={field?.label}
            render={field?.render}
            {...(field.props ?? {})}
            key={index}
          />
        ))}
    </Box>
  );
};
JsonForm.propTypes = {
  mode: PropTypes.string,
  source: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  formFieldMap: PropTypes.array,
  style: PropTypes.object,
  props: PropTypes.object,
};
