import React from 'react';
import { Info } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  infoBox: {
    width: '100%',
    outlineWidth: '0.8rem',
    // backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))',
    lineHeight: '1.43',
    fontWeight: '500',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
    borderRadius: '1rem',
  },
  info: {
    outlineColor: '#08316c',
    color: '#08316c',
  },
  warning: {
    outlineColor: '#f1a416',
    color: '#f1a416',
  },
  error: {
    outlineColor: '#e34228',
    color: '#e34228',
  },
});

export const InfoBox = ({
  children,
  text,
  showIcon = true,
  iconBefore = true,
  level = null,
  ...props
}) => {
  const classes = useStyles();

  if (level && !['default', 'info', 'warning', 'error'].includes(level)) {
    throw new Error(
      `InfoBox: invalid level ${level}, please provide none or one from the following list -> 'default','info','warning','error'`
    );
  }

  return (
    <Typography
      {...props}
      paragraph={true}
      variant={'caption'}
      gutterBottom={false}
      className={`${classes.infoBox} ${classes[level]}`}
    >
      {showIcon && iconBefore && <Info />}
      {text && <span dangerouslySetInnerHTML={{ __html: text }}></span>}
      {children}
      {showIcon && !iconBefore && <Info />}
    </Typography>
  );
};
InfoBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
    PropTypes.node,
  ]),
  text: PropTypes.string,
  showIcon: PropTypes.bool,
  iconBefore: PropTypes.bool,
  level: PropTypes.string,
  props: PropTypes.object,
};

export default InfoBox;
