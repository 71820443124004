import React from 'react';

import { SelectInput, FormDataConsumer, TextInput } from 'react-admin';
import InfoBox from '../InfoBox';
import RichTextInput from 'ra-input-rich-text';

export const StageRegulations = (p) => (
  <>
    <SelectInput
      required
      label="Tipo di regolamento"
      source="extra.regulation_type"
      choices={[
        { id: 'link', name: 'Pagina dinamica su S3' },
        { id: 'text', name: 'Testo' },
      ]}
      {...p}
    ></SelectInput>
    <FormDataConsumer {...p}>
      {({ formData, ...props }) => {
        const rt = formData?.extra?.['regulation_type'];
        if (!rt) {
          return <InfoBox level="warning">Scegliere la tipologia di regolamento!</InfoBox>;
        }
        if (rt === 'link') {
          return (
            <>
              <TextInput
                required
                label="Nome pagina"
                source="extra.regulation_link"
                {...props}
              ></TextInput>
              <InfoBox level="info">
                <span>
                  Inserire solo il nome del file html presente nei contenuti dinamici di S3 (ovvero{' '}
                  <a
                    href="https://oou-uploads.s3-eu-west-1.amazonaws.com/dynamic-page"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://oou-uploads.s3-eu-west-1.amazonaws.com/dynamic-page
                  </a>
                  ).
                  <br />
                  Fare riferimento alla documentazione{' '}
                  <a
                    href="https://exagespa.atlassian.net/wiki/spaces/NFSPRJ/pages/3286958093/AWS+S3#Dynamic-pages"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Confluence | AWS+S3 Dynamic-pages
                  </a>
                </span>
              </InfoBox>
            </>
          );
        }
        if (rt === 'text') {
          return (
            <>
              <InfoBox level="info">
                <span>
                  Inserisci il testo completo del regolamento di questa fase, gli utenti potranno
                  leggerlo direttamente dall&apos;APP!
                </span>
              </InfoBox>
              <RichTextInput
                required
                label="Regolamento"
                source="extra.regulation_text"
                toolbar={[
                  [{ font: [] }],
                  [{ size: ['small', false, 'large', 'huge'] }],
                  [{ color: [] }, { background: [] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ align: [] }],
                  ['clean'],
                  ['link', 'image'],
                ]}
                {...props}
              ></RichTextInput>
            </>
          );
        }
      }}
    </FormDataConsumer>
    <FormDataConsumer {...p}>
      {({ formData }) => {
        const rt = formData?.extra?.['regulation_type'];
        const rl = formData?.extra?.['regulation_link'];
        if (!rt || !rl) {
          return null;
        }
        const link =
          'https://oou-uploads.s3-eu-west-1.amazonaws.com/dynamic-pages/stage-' + rl + '.html';
        return (
          <InfoBox level="warning">
            <span>
              Il link finale corrisponde al seguente:
              <br />
              <a href={link} target="_blank" rel="noreferrer">
                <strong>{link}</strong>
              </a>
              <br />
              <strong style={{ fontSize: '18px' }}>
                Verifica che il link finale sia corretto!
              </strong>
            </span>
          </InfoBox>
        );
      }}
    </FormDataConsumer>
  </>
);
