import React from "react";
import {JsonField, JsonInput} from "react-admin-json-view";
import {JsonForm, JsonFormFieldTypes, JsonFormInputTypes} from "../JsonForm";
import {Box, Chip} from "@material-ui/core";
import InfoBox from "../InfoBox";
import { useFormState } from 'react-final-form';
import {AvailablePlatforms} from "../utils";
import {FeedKPIConfigUI} from "./FeedKPIConfig";
import PropTypes from "prop-types";

export const ConfigEditForm = (props) => {
    const form = useFormState();

    switch (form.values['key']) {
        case 'minimum-platform-version':
            return (<Box>
                <InfoBox level="info" text={"Imposta la versione minima richiesta per ciascuna piattaforma"} />
                <InfoBox level="error" text={"Tutti gli utenti aventi versione minore a quella data saranno costretti ad eseguire un aggiornamento"} />
                <JsonForm
                    {...props}
                    mode="input"
                    source={"value"}
                    formFieldMap={[
                        {
                            type: JsonFormInputTypes.string,
                            source: AvailablePlatforms.ios,
                            label: 'Versione minima iOS',
                        },
                        {
                            type: JsonFormInputTypes.string,
                            source: AvailablePlatforms.android,
                            label: 'Versione minima Android',
                        },
                        {
                            type: JsonFormInputTypes.string,
                            source: AvailablePlatforms.web,
                            label: 'Versione minima web [disabled]',
                            props: {disabled: true},
                        },
                    ]}
                />
            </Box>);
        case 'app-starting-page':
            return (<Box>
                <InfoBox level="info" text={"Impostazioni per la pagina di atterraggio in APP ad ogni nuova sessione"} />
                <JsonForm
                    {...props}
                    mode="input"
                    source={"value"}
                    formFieldMap={[
                        {
                            type: JsonFormInputTypes.select,
                            source: 'page',
                            label: 'Pagina di atterraggio',
                            props: {choices:AppStartingPageRoutes, optionValue:'id', optionsText:'name'},
                        },
                        {
                            type: JsonFormInputTypes.date,
                            source: 'from',
                            label: 'Attivo dal',
                        },
                        {
                            type: JsonFormInputTypes.date,
                            source: 'to',
                            label: 'Attivo al',
                        },
                    ]}
                />
            </Box>);
        case 'feed-pinned-video':
            return (<Box>
                <InfoBox level="info" text={"Impostazioni per il primo video del feed"} />
                <JsonForm
                    {...props}
                    mode="input"
                    source={"value"}
                    formFieldMap={[
                        {
                            type: JsonFormInputTypes.select,
                            source: 'contentType',
                            label: 'Tipo di contenuto',
                            props: {
                                choices:[{id:"partnership", name:"Partnership"},{id:"masterclass", name:"Masterclass"}],
                                optionValue:'id',
                                optionsText:'name',
                                allowEmpty: true,
                            },
                        },
                    ]}
                />
            </Box>);
        case 'is-skillTale-enabled':
            return (<Box>
                <InfoBox level="info" text={"Impostazioni per mostrare/nascondere la feature skillTale in APP"} />
                <JsonForm
                    {...props}
                    mode="input"
                    source={"value"}
                    formFieldMap={[
                        {
                            type: JsonFormInputTypes.bool,
                            source: 'is_active',
                            label: 'Attiva',
                            props: {},
                        },
                        {
                            type: JsonFormInputTypes.number,
                            source: 'max-users',
                            label: 'Limite d\'utilizzo (numero di utenti)',
                            props: {},
                        },
                    ]}
                />
            </Box>);
        case 'api.video-feed.kpi':
            return <FeedKPIConfigUI {...props} input={true} />
        default:
            return <JsonInput
                {...props}
                source="value"
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: true,
                    displayDataTypes: true,
                }}
            />
    }
}
ConfigEditForm.propTypes = { };

export const ConfigShowForm = props => {
    switch (props?.record?.key) {
        case 'minimum-platform-version':
            return (<Box>
                <JsonForm
                    {...props}
                    mode="field"
                    source={"value"}
                    formFieldMap={[
                        {
                            type: JsonFormFieldTypes.func,
                            source: AvailablePlatforms.ios,
                            label: 'Versione minima iOS',
                            render: record => record?.[AvailablePlatforms.ios] ? <Chip label={record?.[AvailablePlatforms.ios]} /> : 'No minimum version'
                        },
                        {
                            type: JsonFormFieldTypes.func,
                            source: AvailablePlatforms.android,
                            label: 'Versione minima Android',
                            render: record => record?.[AvailablePlatforms.android] ? <Chip label={record?.[AvailablePlatforms.android]} /> : 'No minimum version'
                        },
                        {
                            type: JsonFormFieldTypes.func,
                            source: AvailablePlatforms.web,
                            label: 'Versione minima web [disabled]',
                            props: {disabled: true},
                            render: record => record?.[AvailablePlatforms.web] ? <Chip label={record?.[AvailablePlatforms.web]} /> : 'No minimum version'
                        },
                    ]}
                />
            </Box>);
        case 'app-starting-page':
            return (<Box>
                <JsonForm
                    {...props}
                    mode="field"
                    source="value"
                    formFieldMap={[
                        {
                            type: JsonFormFieldTypes.func,
                            source: 'page',
                            label: 'Pagina di atterraggio',
                            render: record => AppStartingPageRoutes.find(_ => _.id === record.page)?.name
                        },
                        {
                            type: JsonFormFieldTypes.date,
                            source: 'from',
                            label: 'Attivo dal',
                        },
                        {
                            type: JsonFormFieldTypes.date,
                            source: 'to',
                            label: 'Attivo al',
                        },
                    ]}
                />
            </Box>);
        case 'feed-pinned-video':
            return (<Box>
                <JsonForm
                    {...props}
                    mode="field"
                    source={"value"}
                    formFieldMap={[
                        {
                            type: JsonFormFieldTypes.func,
                            source: 'contentType',
                            label: 'Tipo di contenuto fissato nel feed',
                            render: record => [{id:"partnership", name:"Partnership"},{id:"masterclass", name:"Masterclass"}].find(
                                i => i.id === record || i.id === record?.contentType
                            )?.name ?? "None",
                        },
                    ]}
                />
            </Box>);
        case 'is-skillTale-enabled':
            return (<Box>
                <JsonForm
                    {...props}
                    mode="field"
                    source={"value"}
                    formFieldMap={[
                        {
                            type: JsonFormFieldTypes.func,
                            source: 'is_active',
                            label: 'Visibilità feature SkillTale',
                            render: record => record?.is_active ? 'Visibile' : 'Nascosto',
                            props: {},
                        },
                        {
                            type: JsonFormFieldTypes.func,
                            source: 'max-users',
                            label: 'Limite d\'utilizzo',
                            render: record => record?.['max-users'] ? record?.['max-users'] + ' utenti' : 'Nessun limite',
                            props: {},
                        },
                    ]}
                />
            </Box>);
        case 'api.video-feed.kpi':
            return <FeedKPIConfigUI {...props} input={false} />
        default:
            return <JsonField
                {...props}
                source="value"
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: true,
                    displayDataTypes: true,
                }}
            />
    }
}
ConfigShowForm.propTypes = {
    page: PropTypes.oneOf([undefined, 'list', 'show']),
    props: PropTypes.object,
};

export const AppStartingPageRoutes = [
    {id:'achievement',name:'Profilo: Tab Badge'},
    {id:'challenge',name:'Sfide'},
    {id:'feed',name:'Feed'},
    {id:'leaderboard',name:'Leaderboard'},
    {id:'masterclass',name:'Masterclass'},
    {id:'news',name:'News'},
    {id:'notifications',name:'Notifiche'},
    {id:'pass',name:'Profilo: Tab Pass'},
    {id:'pass-yc',name:'Impostazioni: Pass YourChance'},
    {id:'profile-completed',name:'Profilo'},
    {id:'winner',name:'Stages'},
].sort((a, b) => a.name.localeCompare(b.name));