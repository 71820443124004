import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { TextField, DateField, Filter, SearchInput } from 'react-admin';
import {NotifiableTargetField, TournamentTargetField} from './news.util';

const NewsFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
  </Filter>
);

const NewsList = (props) => (
  <ListGuesser
    {...props}
    title="List of news"
    perPage={25}
    filters={<NewsFilter />}
    sort={{ field: 'title', order: 'DESC' }}
  >
    <TextField source="title" />
    <NotifiableTargetField label="Destinatari" />
    <TournamentTargetField label="Torneo" source="title"/>
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
  </ListGuesser>
);

export default NewsList;
