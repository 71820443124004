import React from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceField,
  ReferenceInput,
  SelectInput,
  FunctionField,
  useGetList,
  Loading,
  Error,
  ArrayField,
  SingleFieldList,
} from 'react-admin';
import { Chip } from '@material-ui/core';

/**
 * @param {{}} partnership
 */
export function getPartnershipDisplayName(partnership) {
  return partnership.name + (partnership.partner?.name ? ` [${partnership.partner?.name}]` : '');
}

export const PartnershipField = ({ record }) => {
  if (!record) return null;

  return <Chip label={record.name} />;
};
PartnershipField.propTypes = {
  record: PropTypes.object,
  props: PropTypes.any,
};

export const PartnershipReferenceField = ({ source, reference, label, ...props }) => {
  return (
    <ReferenceField
      label={label ?? 'Partnership'}
      source={source ?? 'partnership'}
      reference={reference ?? 'partnerships'}
      link="show"
      sortable={false}
      {...props}
    >
      <FunctionField render={(record) => <PartnershipField record={record} />} />
    </ReferenceField>
  );
};
PartnershipReferenceField.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};

export const PartnershipSelectInput = ({ source, label, reference, ...props }) => {
  return (
    <ReferenceInput
      label={label ?? 'Partnership'}
      source={source ?? 'partnership'}
      reference={reference ?? 'partnerships'}
      link="show"
      sortable={false}
      {...props}
    >
      <SelectInput translateChoice={false} optionText={<PartnershipField />} />
    </ReferenceInput>
  );
};
PartnershipSelectInput.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};

/**
 * @param {string} label
 * @param {string} source
 * @param {{ [key:string]: any }} dataFilters Filters the list of partnerships, default = { active:true }
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
export const PartnershipSignupFlowSelectInput = ({ label, source, dataFilters = {}, ...props }) => {
  const { data, loading, error } = useGetList(
    'partnerships',
    {},
    {},
    {
      pagination: false,
      // ------ default filters start
      active: true,
      // ------ default filters end
      ...dataFilters,
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <SelectInput
      {...props}
      translateChoice={false}
      choices={Object.entries(data ?? {}).map((entry) => entry?.[1])}
      optionText={(record) => <PartnershipField record={record} />}
      optionValue={'signupFlow'}
      source={source}
      label={label}
    />
  );
};
PartnershipSignupFlowSelectInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  dataFilters: PropTypes.string,
  props: PropTypes.object,
};

export const PartnershipSignupFlowArrayField = ({data, loading, error, isList = true, ...props}) => {
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  const style = {
    padding: isList ? undefined : '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: '1 0 30%',
    gap: '5px'
  };
  const getPartnership = record => Object.entries(data ?? {}).map(e => e[1]).find(p => p?.signupFlow === record);
  return <ArrayField source="attributes" {...props} style={style}>
    <SingleFieldList>
      <FunctionField render={record => <PartnershipField record={getPartnership(record)} />} />
    </SingleFieldList>
  </ArrayField>
};
PartnershipSignupFlowArrayField.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  isList: PropTypes.bool,
  props: PropTypes.object,
};