import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  useDataProvider,
  useNotify,
  SelectInput,
    ReferenceInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import AsideView from '../asideView';
import { validateTitle, validateText } from './validators';
import mediaCreator from '../mediaCreator';
import { NewsRecipientTypes } from './news.util';

const ChallengesEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return (
    <Edit
      aside={<AsideView />}
      {...props}
      transform={async (data) => {
        const createCover = mediaCreator({
          dataProvider,
          notify,
          mediaFieldLabel: 'cover',
          fileLabel: 'fileCover',
        });
        const createVideo = mediaCreator({
          dataProvider,
          notify,
          mediaFieldLabel: 'video',
          fileLabel: 'fileVideo',
        });
        try {
          const withCover = await createCover(data);
          const withVideo = await createVideo(withCover);
          return withVideo;
        } catch (error) {
          notify(`Errore inatteso: ${error.message}`, 'warning');
        }
        return data;
      }}
    >
      <SimpleForm>
        <TextInput source="title" validate={validateTitle} />
        <RichTextInput
          validate={validateText}
          source="text"
          toolbar={[
            [{ font: [] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ color: [] }, { background: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image'],
          ]}
        />
          <ReferenceInput
              label="Torneo"
              source="tournament"
              reference="tournaments"
              allowEmpty={true}
          >
              <SelectInput optionText="title"/>
          </ReferenceInput>
        <SelectInput
          source="notifiableTarget"
          label="Destinatari [tutti]"
          allowEmpty={true}
          choices={NewsRecipientTypes}
        />
        <FileInput
          source="fileCover"
          label="Immagine cover"
          placeholder={
            <p>
              Se vuoi sostituire la cover, trascina qui un file immagine, altrimenti verrà mantenuta
              la cover corrente
            </p>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          source="fileVideo"
          label="Video"
          placeholder={
            <p>
              Se vuoi sostituire il video da associare alla notizia, trascina qui un file video,
              altrimenti verrà mantenuto il video corrente
            </p>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export default ChallengesEdit;
