import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  DateInput,
  BooleanInput,
  required,
  SelectArrayInput,
  SelectInput,
  ReferenceInput,
  ChipField,
} from 'react-admin';
import { PartnerSelectInput } from '../partner/utils';
import { USER_CLUSTERS, UserClusterField } from '../UserClusters';
import { tournamentOptionText } from '../utils';

const PartnershipEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="description" validate={[required()]} />
      <SelectArrayInput
        source="targets"
        translateChoice={false}
        choices={USER_CLUSTERS}
        optionText={(record) => <UserClusterField record={record} source="id" />}
      ></SelectArrayInput>
      <SelectInput
        source="gender"
        translateChoice={false}
        choices={[
          { id: 'M', name: 'Maschio' },
          { id: 'F', name: 'Femmina' },
        ]}
      />
      <TextInput source="signupFlow" validate={[required()]} />
      <PartnerSelectInput source="partner" validate={[required()]} />
      <ReferenceInput
        source="tournament"
        reference="tournaments"
        sortable={false}
        allowEmpty={true}
        filterToQuery={() => ({
          'endDate[strictly_after]': new Date().toLocaleDateString('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          }),
        })}
      >
        <SelectInput
          translateChoice={false}
          allowEmpty={true}
          optionText={(record) => (
            <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
          )}
        />
      </ReferenceInput>
      <DateInput source="startDate" />
      <DateInput source="endDate" />
      <BooleanInput label="Visibile" source="visible" />
    </SimpleForm>
  </Edit>
);

export default PartnershipEdit;
