import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Box, makeStyles } from '@material-ui/core';
import {
  TextInput,
  NumberInput,
  SelectInput,
  Create,
  SimpleForm,
  useDataProvider,
  useNotify,
  ReferenceInput,
  SaveButton,
  Toolbar,
  required,
  minValue,
  maxValue,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  Loading,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import mediaCreator from '../mediaCreator';
import { tournamentOptionText } from '../utils';
import { MasterclassMap } from './masterclass.utils';
import { PlayerRoleField } from '../fields';
import { enforceMaterializedViewResource } from '../materialized-views/mat-view.utils';
import InfoBox from '../InfoBox';
import { ImageUploadInput, VideoUploadInput } from '../fields/media.utils';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const useStyles = makeStyles({
  customFieldList: {
    '& > *': {
      width: '100%',
    },
  },
});

const MasterclassCreate = ({ ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const [loading, isLoading] = useState(false);
  const [tournamentId, setTournamentId] = useState(null);
  const [tournament, setTournament] = useState(null);
  const [availableCategories, setAvailableCategories] = useState(MasterclassMap);

  useEffect(() => {
    if (tournamentId) {
      dataProvider.getOne('tournament', { id: tournamentId }).then((t) => {
        const tou = t?.data ?? t;
        setTournament(tou);
        setAvailableCategories(MasterclassMap.filter((c) => !tou?.masterclasses?.[c.id]));
      });
    }
  }, [tournamentId]);

  if (loading) {
    return <Loading />;
  }

  if (!availableCategories.length) {
    return (
      <InfoBox level="error" style={{ width: '100%', padding: '8rem 0' }}>
        <span style={{ fontSize: '16px' }}>
          <strong>Hai già creato tutte le masterclass per questo torneo!</strong>
          <br />
          Suggerimento: torna alla lista e modifica le masterclass esistenti del torneo &quot;
          <strong>{tournament?.title}</strong>&quot;.
        </span>
      </InfoBox>
    );
  }

  return (
    <Create
      {...enforceMaterializedViewResource(props)}
      transform={async (data) => {
        isLoading(true);
        const createPoster = mediaCreator({
          dataProvider,
          notify,
          mediaFieldLabel: 'poster',
          fileLabel: 'poster',
        });
        const createVideo = mediaCreator({
          dataProvider,
          notify,
          mediaFieldLabel: 'path',
          fileLabel: 'video',
        });
        const promises = [];
        [1, 2, 3].forEach((banner) => {
          if (data[`b${banner}`]) {
            promises.push(
              mediaCreator({
                dataProvider,
                notify,
                mediaFieldLabel: `banner${banner}`,
                fileLabel: `b${banner}`,
              })(data).then((d) => {
                data[`banner${banner}`] = d[`banner${banner}`];
              })
            );
          }
        });
        for (let index = 0; index < (data.items?.length ?? 0); index++) {
          promises.push(
            (async (i) => {
              const item = data.items[i];
              try {
                if (item.poster || item.video) {
                  const m = { ...data.items[i].media };
                  const result = await createVideo(await createPoster(item));
                  if (result.poster) {
                    data.items[i].media.poster = result.poster;
                  }
                  if (result.path) {
                    data.items[i].media.path = result.path;
                  }
                  if (m.path && !data.items[i].media.path) {
                    data.items[i].media.path = m.path;
                  }
                  if (m.poster && !data.items[i].media.poster) {
                    data.items[i].media.poster = m.poster;
                  }
                }
              } catch (error) {
                notify(`Errore inatteso: ${error.message}`, 'warning');
              }
            })(index)
          );
        }
        await Promise.allSettled(promises);
        isLoading(false);
        return data;
      }}
    >
      <SimpleForm toolbar={<EditToolbar></EditToolbar>}>
        <ReferenceInput
          label="Torneo"
          source="tournament"
          reference="tournaments"
          validate={[required()]}
          onChange={(event) => setTournamentId(event.target.value)}
        >
          <SelectInput
            optionText={(record) => tournamentOptionText(record)}
            translateChoice={false}
          />
        </ReferenceInput>
        <SelectInput
          validate={[required()]}
          source="category"
          label="Ruolo"
          choices={availableCategories}
          optionText={(role) => (
            <Box display="flex" justifyContent="flex-start">
              <PlayerRoleField source="id" record={role}></PlayerRoleField>
              <Typography variant="body1">{role.name}</Typography>
            </Box>
          )}
          translateChoice={false}
        />
        <ImageUploadInput source="b1" label="Banner (1x)">
          Se vuoi sostituire l&apos;immagine cover, trascina qui la nuova immagine, altrimenti verrà
          mantenuta l&apos;immagine corrente
        </ImageUploadInput>
        <ImageUploadInput source="b2" label="Banner (2x)">
          Se vuoi sostituire l&apos;immagine cover, trascina qui la nuova immagine, altrimenti verrà
          mantenuta l&apos;immagine corrente
        </ImageUploadInput>
        <ImageUploadInput source="b3" label="Banner (3x)">
          Se vuoi sostituire l&apos;immagine cover, trascina qui la nuova immagine, altrimenti verrà
          mantenuta l&apos;immagine corrente
        </ImageUploadInput>
        <BooleanInput label="Completato" source="complete" />
        <BooleanInput label="Disabilitato" source="disabled" validate={[required()]} />
        <ArrayInput label="Elenco masterclass" source="items" className={classes.customFieldList}>
          <SimpleFormIterator>
            <NumberInput label="ID" source="id" validate={[required(), minValue(0)]} />
            <InfoBox>
              Il campo &quot;ID&quot; ti permette di riordinare gli elementi, assicurati che i
              numeri siano univoci all&apos;interno di questa lista
            </InfoBox>
            <TextInput label="Name" source="name" validate={[required(), minValue(1)]} />
            <RichTextInput
              label="Descrizione"
              source="description"
              validate={[required(), minValue(10), maxValue(255)]}
            />
            <InfoBox>
              Il testo del campo &quot;Descrizione&quot; dev&apos;essere almeno 10 caratteri fino ad
              un massimo di 255
            </InfoBox>
            <BooleanInput label="Premium" source="premium" validate={[required()]} />
            <BooleanInput label="Disabilitato" source="disabled" />
            <ImageUploadInput source="poster" label="Immagine cover">
              Se vuoi sostituire l&apos;immagine cover, trascina qui la nuova immagine, altrimenti
              verrà mantenuta l&apos;immagine corrente
            </ImageUploadInput>
            <VideoUploadInput source="video" label="Video masterclass">
              Se vuoi sostituire il video masterclass, trascina qui il nuovo video, altrimenti verrà
              mantenuto il video corrente
            </VideoUploadInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
MasterclassCreate.propTypes = {
  props: PropTypes.object,
};

export default MasterclassCreate;
