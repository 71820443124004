import React from 'react';
import { FunctionField, required } from 'react-admin';
import { useForm } from 'react-final-form';
import { Typography, Box, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import {JsonForm, JsonFormFieldTypes, JsonFormInputTypes} from "../JsonForm";
import {getAvailablePlatforms, getServerEvents, SERVER_EVENTS_MAP} from "../utils";
import {AvailablePlatformsField} from "../OperatingSystemsField";
import InfoBox from "../InfoBox";

export const USER_BENEFIT_TYPES = {
  banner: { text: 'Banner', color: '#afe751' },
  feed: { text: 'Contenuto feed', color: '#ff7d6c' },
  'user-upgrade': { text: 'Upgrade automatico', color: '#fdd944' },
  // news: { text: 'Contenuto news', color: '#6c93fc' },
  'sponsor-code': { text: 'Invio sconto', color: '#c2c3c5' },
  'detail-page': { text: 'Pagina di promozione', color: '#22e195' },
};

/**
 * @param {string[]} omit benefit to exclude from the newly created map
 * @param {boolean} keep instead of omitting, keeps only the given benefits
 * @return {{text: string, value: string}[]}
 */
export function getUserBenefitTypesMap(omit = null, keep = false) {
  const matches = banner => omit !== null && (
      (omit?.includes(banner?.value) && !keep)
      || (!omit?.includes(banner?.value) && keep)
  );
  return Object.entries(USER_BENEFIT_TYPES)
    .map((entry) => ({
      value: entry[0],
      text: entry[1].text,
    }))
    .reduce((prev, curr) => (
      matches(curr) ? prev : [ ...prev, curr ]
    ), []);
}

export const UserBenefitTypeFunctionField = (props) => (
  <FunctionField
    {...props}
    render={(record) => (
      !USER_BENEFIT_TYPES[record.type] ? 'None' : <Chip
        label={USER_BENEFIT_TYPES[record.type]?.text}
        style={{ backgroundColor: USER_BENEFIT_TYPES[record.type]?.color }}
      />
    )}
  />
);

export const UserBenefitTypeField = ({ record, label = 'Type', ...props }) => (
  <Box>
    <Typography className={['MuiFormLabel-root', 'MuiInputLabel-shrink']}>{label}</Typography>
    <UserBenefitTypeFunctionField record={record} {...props} />
  </Box>
);
UserBenefitTypeField.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  props: PropTypes.object,
};

export const UserBenefitJsonContentShowUI = (props) => {

    switch (props?.record?.type) {
        case 'banner':
            return <UserBenefitBannerShowUI {...props} />;
        case 'feed':
            return null;
        case 'user-upgrade':
            return <UserBenefitUserUpgradeShowUI {...props} />;
        case 'news':
            return null;
        case 'sponsor-code':
            return <UserBenefitSponsorCodeShowUI {...props} />;
        case 'detail-page':
            return <UserBenefitDetailPageShowUI {...props} />;
        default:
            return null;
    }
}
UserBenefitJsonContentShowUI.propTypes = {
    record: PropTypes.object,
};

export const UserBenefitJsonContentEditUI = (props) => {
    const form = useForm();

    switch (form.getState().values?.type) {
        case 'banner':
            return <UserBenefitBannerEditUI {...props} />;
        case 'feed':
            return null;
        case 'user-upgrade':
            return <UserBenefitUserUpgradeEditUI {...props} />;
        case 'news':
            return null;
        case 'sponsor-code':
            return <UserBenefitSponsorCodeEditUI {...props} />;
        case 'detail-page':
            return <UserBenefitDetailPageEditUI {...props} />;
        default:
            return null;
    }
}

// JSON field UI forms

const BASE_EDIT_JSON_UI_FIELDS = [
    {
        type: JsonFormInputTypes.select,
        source:'platform',
        label:'Platform',
        props: {choices:getAvailablePlatforms(true), optionText:'name', optionValue:'id', allowEmpty:true},
    },
    {
        type:JsonFormInputTypes.select,
        source:'triggerEvent',
        label:'Trigger event',
        props: {choices:getServerEvents(true), optionText:'name', optionValue:'id', allowEmpty:true},
    }
];
const BASE_SHOW_JSON_UI_FIELDS = [
    {
        type:JsonFormFieldTypes.func,
        source:'platform',
        label:'Platform',
        render: record => <AvailablePlatformsField
            source="platform"
            record={record}
            platforms={getAvailablePlatforms()}
            infoBoxText="Il campo non risulta impostato, il benefit sarà disponibile a tutte le piattaforme (iOS e Android (APP), Web (Landing))"
            infoBoxLevel="info"
        />
    },
    {
        type:JsonFormFieldTypes.func,
        source:'triggerEvent',
        label:'Trigger event',
        render: record => {
            const value = SERVER_EVENTS_MAP[record.triggerEvent];

            if (!value) return <InfoBox level="warning" text="Il campo non risulta impostato, a seconda della tipologia di benefit questo potrà essere attribuito allo scatenarsi di un qualsiasi evento disponibile" />;

            return value;
        }
    }
];

/* ------------------ banner ------------------ */

export const UserBenefitBannerEditUI = (props) => (
    <JsonForm {...props} mode={'input'} formFieldMap={[
        {
            type: JsonFormInputTypes.string,
            source:'title',
            label:'Banner title',
            props: {inputProps:{maxLength: 30}, validate:[required()]}
        },
        {
            type: JsonFormInputTypes.string,
            source:'body',
            label:'Banner description',
            props: {inputProps:{maxLength: 60}, validate:[required()]}
        },
    ]} />
);

export const UserBenefitBannerShowUI = (props) => (
    <JsonForm {...props} mode={'field'} formFieldMap={[
        {
            type: JsonFormFieldTypes.string,
            source:'title',
            label:'Banner title',
        },
        {
            type: JsonFormFieldTypes.string,
            source:'body',
            label:'Banner description',
        },
    ]} />
);

/* ------------------ feed ------------------ */
/* ------------------ user-upgrade ------------------ */

export const UserBenefitUserUpgradeEditUI = (props) => (
    <JsonForm {...props} mode={'input'} formFieldMap={[
        ...BASE_EDIT_JSON_UI_FIELDS,
        {
            type: JsonFormInputTypes.reference,
            source:'inAppProduct',
            label:'In app product',
            render: record => record.name,
            props: {reference:'in_app_products',link:'show',validate:[required()]}
        },
    ]} />
);

export const UserBenefitUserUpgradeShowUI = (props) => (
    <JsonForm {...props} mode={'field'} formFieldMap={[
        ...BASE_SHOW_JSON_UI_FIELDS,
        {
            type: JsonFormInputTypes.reference,
            source: 'inAppProduct',
            label: 'In app product',
            render: (record) => (<Chip label={record.name}/>),
            props: {reference: 'in_app_products', link: 'show'}
        },
    ]} />
);

/* ------------------ news ------------------ */
/* ------------------ sponsor-code ------------------ */

export const UserBenefitSponsorCodeEditUI = (props) => (
    <JsonForm {...props} mode={'input'} formFieldMap={[
        ...BASE_EDIT_JSON_UI_FIELDS,
        {
            type:JsonFormFieldTypes.string,
            source:'redeemUrl',
            label:'Redeem url',
            props: {validate:[required()]},
        },
    ]} />
);

export const UserBenefitSponsorCodeShowUI = (props) => (
    <JsonForm {...props} mode={'field'} formFieldMap={[
        ...BASE_SHOW_JSON_UI_FIELDS,
        {
            type:JsonFormFieldTypes.string,
            source:'redeemUrl',
            label:'Redeem url',
        },
    ]} />
)

/* ------------------ detail page ------------------ */

export const UserBenefitDetailPageEditUI = (props) => (
    <JsonForm {...props} mode={'input'} formFieldMap={[
        {
            type:JsonFormInputTypes.htmlEditor,
            source:'body',
            label:'Body',
            required: true,
        },
        {
            type:JsonFormInputTypes.select,
            source:'benefitType',
            label:'Benefit Type',
            props: {choices:getUserBenefitTypesMap(['sponsor-code'], true), optionText:"text", optionValue:"value"},
        },
    ]} />
);

export const UserBenefitDetailPageShowUI = (props) => (
    <JsonForm {...props} mode={'field'} formFieldMap={[
        {
            type:JsonFormFieldTypes.html,
            source:'body',
            label:'Body',
        },
        {
            type:JsonFormFieldTypes.func,
            source:'benefitType',
            label:'Benefit Type',
            render: record => (
                <UserBenefitTypeFunctionField record={{type: record?.benefitType}}/>
            ),
        },
    ]} />
);