import React from 'react';
import { BooleanField, DateField, TextField, NumberField } from 'react-admin';
import PropTypes from 'prop-types';
import { ListGuesser } from '@api-platform/admin';
import { isAdmin, isEditor } from '../utils';
import UserBenefitListActions from './UserBenefitListActions';
import UserBenefitFilters from './UserBenefitFilters';
import { UserBenefitTypeFunctionField } from './util';
import { PartnershipReferenceField } from '../partnership/utils';

const hasPermissions = (permissions) => isEditor(permissions) || isAdmin(permissions);

const UserBenefitList = ({ permissions, ...props }) => {
  return (
    <ListGuesser
      {...props}
      title={'List of partners'}
      perPage={25}
      filters={<UserBenefitFilters permissions={permissions} {...props} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<UserBenefitListActions />}
    >
      <TextField source="name" />
      <UserBenefitTypeFunctionField label="Type" />
      <PartnershipReferenceField label="Partnership" source="partnership" />
      <NumberField source="supply" label="Slot totali" />
      <NumberField source="availableSupply" label="Slot disponibili" />
      <BooleanField source="oneTimeOnly" label="Monouso" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <BooleanField label="Main" source="main" />
      {hasPermissions(permissions) && <BooleanField source="visible" />}
      <DateField source="createdAt" />
      {hasPermissions(permissions) && <DateField source="updatedAt" />}
    </ListGuesser>
  );
};
UserBenefitList.propTypes = {
  permissions: PropTypes.array,
  props: PropTypes.any,
};

export default UserBenefitList;
